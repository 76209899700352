import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import LinkExternal from "../../components/link_external"
import Seo from "../../components/seo"
import Routes from "../../routes"
import GeorgePellImg from "../../images/cardinal-george-pell.jpg"
import { useImpression } from "../../analytics/utils"

const GeorgePell = ({ location }) => {
  useImpression("GeorgePell")
  return (
    <Layout location={location}>
      <Seo
        title="George Pell Catholic Archbishop Appeal"
        description="Cardinal George Pell's appeal - The highest ranking Catholic in Australia has been jailed for child sexual abuse against two 13 year old boys."
      />
      <h1>George Pell</h1>
      <img
        src={GeorgePellImg}
        alt="Cardinal George Pell convicted for sex abuse of children"
        width={400}
        style={{ float: "right", padding: 20 }}
      />
      <p>
        George Pell was the highest-ranking Catholic in Australia's church. He
        was the Vatican's third most powerful cleric. Pell served as the
        archbishop of Melbourne. But that all came to an end.
      </p>
      <p>
        A jury convicted Pell of twelve sexual crimes against two
        thirteen-year-old boys back in the late 1990s. Pell is guilty of five
        offenses that took place inside St. Patrick's Cathedral, all while he
        was archbishop of Melbourne.
      </p>
      <p>
        Pell even launched "
        <LinkExternal to="https://www.cam.org.au/Safeguarding-Children-Young-People-and-Vulnerable-Persons/Melbourne-Response">
          The Melbourne Response
        </LinkExternal>
        " in an attempt to respond to the scandal crisis. And as we now know, he
        was doing exactly what his campaign was targeting, the sexual abuse of
        children. Pell is a prime example of someone who takes advantage of
        their power in the Catholic church. Furthermore, he depicts a
        disgusting, depraved individual. Someone who was forced into abstinence
        by their religion, an idea that doesn't seem to make any sense, and
        instead took out that sexual tension with children. This horrible person
        represents how cunning and manipulative the church can really be.
      </p>
      <p>
        Pell would even attend the choir practice regularly while archbishop.
        After one mass, two of boys left the precession. They went into the
        sacristy inside of the Cathedral. Pell caught them there drinking wine
        and told them they were in trouble. He went on to expose himself and
        rape the boys.
      </p>
      <p>
        Now 78, Pell was sentenced to six years in prison. Unsurprisingly he has
        appealed in court, and we still don't know if George Pell will taste
        freedom again. Pell continues to assert his innocence.
      </p>
      <p>
        Pell's defense is now claiming that the trial wasn't fair, and that
        guilty verdicts were unreasonable given the evidence that was obtained.
        However, prosecutors say that the victim is compelling and telling the
        truth.
      </p>
      <p>
        There will surely be a long legal road ahead, as more than twenty other
        charges of child sex abuse have been filed.
      </p>
      <b>Update as of August 21, 2019</b>
      <br />
      <br />
      <p>
        George Pell has lost his appeal against his convictions and will be
        staying in prison. Learn more about it at{" "}
        <LinkExternal to="https://www.thedailybeast.com/cardinal-george-pell-will-stay-in-prison-after-losing-appeal-for-clerical-sex-abuse-in-australia-19">
          The Daily Beast
        </LinkExternal>
        .
      </p>
      <br />
      <h2>Relevant articles and videos</h2>
      <ul>
        <li>
          <LinkExternal to="https://www.firstthings.com/web-exclusives/2019/08/the-australian-disgrace">
            The Australian Disgrace
          </LinkExternal>
        </li>
        <li>
          <LinkExternal to="https://www.cbsnews.com/news/cardinal-george-pell-loses-appeal-of-sex-abuse-convictions/">
            Cardinal George Pell Loses Appeal of Sex Abuse Convictions
          </LinkExternal>
        </li>
      </ul>
      <hr />
      <p>
        Be sure to check out our <Link to={Routes.RESOURCES}>Resources</Link>{" "}
        page for more interesting content.
      </p>
    </Layout>
  )
}

export default GeorgePell
